import axios from "axios";

//Demo
export const baseConfig = axios.create({
	baseURL: "https://aira.talrop.works/api/v1/",
});

//Live
// export const baseConfig = axios.create({
// 	baseURL: "https://app.airaspaandwellness.com/api/v1/",
// });
